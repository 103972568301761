@import "header", "items", "experience";

.about-page {
  @include gungnir-font();
  // margin-top: 150px;
  min-height: 550px;

  h1, h2, h3, h4, h5, h6, p {
    @include gungnir-font();
  }
  p {
    line-height: 1.61em;
  }
  .about-blank {
    height: 150px;
  }
  .about-title {
    font-weight: 600;
    font-size: 2em;
    line-height: 1.4em;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 50px;
  }
  .section {
    padding: 50px 0;
  }

  /*  ------------------- Markdown ------------------- */
  .post-container {
    margin: 0 auto;
    padding: 0;
    width: 65%;
    font-size: 18px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      margin-bottom: 20px;
    }
    @media (max-width: $MQMobile) {
      width: 85%;
    }
  }
}
