.links-page {
  margin: 0 auto;

  h2, span, .desc {
    @include gungnir-font();
  }

  .link-list {
    padding: 10px 0 50px;
    width: 65%;
    max-width: 745px;
    margin: 0 auto;

    .section-title {
      font-weight: 400;
      font-size: 18px;
      padding-left: 10px;
      border-left: 3px solid var(--primary-color);
      margin: 50px 0 20px;
    }

    ul {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 33.33%);

      li {
        margin: 4px;
        border: 1px solid var(--border-color);
        padding: 10px 30px 10px 20px;
        border-radius: 10px;
        overflow: hidden;
        @include transition(all 0.3s);

        a {
          text-decoration: none;
        }
        img {
          cursor: auto;
          padding: 2px;
          border-radius: 100%;
          max-width: 100%;
          float: right;
          box-shadow: inset 0 0 10px var(--body-color);
          margin-top: 5px;
          margin-right: -15px;
          width: 65px;
          height: 65px;
          @include transition(all ease 1s);
        }
        &:hover {
          border: 1px solid transparent;
          // img {
          //     @include transform(rotate(360deg));
          // }
          @include transform(translate(0px, -2px));
          box-shadow: var(--item-up-shadow);
        }
        span.sitename {
          color: var(--primary-color);
          padding-bottom: 10px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
        }
        .desc {
          color: var(--body-gray);
          font-size: 13px;
          padding: 10px 0;
          border-top: 1px dashed #ddd;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 15px;
        }
      }
    }
  }

  @media (max-width: $MQIpad) {
    .link-list {
      width: 85%;
      ul {
        grid-template-columns: repeat(2, 49.5%)
      }
    }
  }

  @media (max-width: $MQSmall) {
    .link-list {
      width: 100%;
      ul {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }
}
