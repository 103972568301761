/* Hux customize Style for Duoshuo */
.comment {
  margin-top: 20px;
  #ds-thread {
    #ds-reset {
      a.ds-like-thread-button {
        border: 1px solid #ddd;
        border-radius: 0px;
        background: white;
        box-shadow: none;
        text-shadow: none;
      }
      a.ds-thread-liked {
      }
      li.ds-tab a.ds-current {
        border: 1px solid #ddd;
        border-radius: 0px;
        background: white;
        box-shadow: none;
        text-shadow: none;
      }
      .ds-textarea-wrapper {
        //border:1px solid #ddd;
        background: none;
      }
      .ds-gradient-bg {
        background: none;
      }
      .ds-post-options {
        border-bottom: 1px solid #ccc;
      }
      .ds-post-button {
        border-bottom: 1px solid #ccc;
      }
      //v1.6 flatten button
      .ds-post-button {
        background: white;
        box-shadow: none;
        &:hover {
          background: $gray-light;
        }
      }
    }
  }
}

#ds-smilies-tooltip ul.ds-smilies-tabs li a {
  background: white !important;
}
