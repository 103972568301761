// Navigation

// Renovamen: modify styles of navbar menu on mobile
.mobile-nav {
  display: none;
}

.main-container {
  height: 100%;
  // overflow: hidden;
  background-color: transparent;
}

@media (max-width: $MQDesktop) {
  .main-container {
    $nav-transition: transform 0.5s, border-radius 1s;
    @include transition($nav-transition);
    position: relative;
    // height will be set to $(window).height() in gungnir.js
    // overflow-y: scroll;
    // overflow-x: hidden;
    &.open {
      @include transform(translateX(250px) translateY(0px) scale(0.9));
      border-radius: 10px;
    }
    &.open-delay {
      background-color: var(--bg);
      box-shadow: -20px 18px 0px 0px rgba(255, 255, 255, 0.2);
      overflow: hidden;
    }
  }
  .mobile-nav {
    position: absolute;
    width: 250px;
    max-width: 250px !important;
    height: 100%;
    top: 0;
    left: -250px;
    display: block;
    overflow-x: hidden !important;
    overflow-y: auto;
    background-color: $brand-primary;
    @include transition(transform 0.5s);
    @include gungnir-font();

    &.open {
      @include transform(translateX(250px));
    }

    p {
      color: lighten($brand-primary, 50%);
    }

    .mobile-menu-avatar {
      position: relative;
      width: 250px;
      height: auto;
      margin: 0 auto;
      padding: 30px 0 20px;
      text-align: center;
      margin-top: 30px;
      .avatar-container {
        width: 90px;
        height: 90px;
        margin: 0 auto;
        border-radius: 100%;
        background-color: lighten($brand-primary, 40%);
        box-shadow: 0px 10px 25px 0px rgba(lighten($brand-primary, 40%), 0.27);
        img {
          padding: 2px;
          max-width: 100%;
          border-radius: 100%;
        }
      }
    }
    .mobile-menu-heading {
      text-align: center;
      font-size: 17px;
      font-weight: bold;
      margin-top: -5px;
    }
    // Social links
    .list-inline {
      font-size: 11px;
      margin: -20px auto 0;
      margin-bottom: 40px;
      position: relative;
      a {
        text-decoration: none;
        font-size: 13px;
        .fa-stack {
          width: 20px;
          .fa-circle {
            color: lighten($brand-primary, 10%);
            display: none;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 18em;
        height: 0.05em;
        background-color: lighten($brand-primary, 50%);
        left: 50%;
        @include transform(translateX(-50%));
      }
    }
    ul {
      height: auto;
      i {
        width: 25px;
      }
      li {
        transition-duration: 0.25s;
        display: block;
        padding: 6px 40px;
        a {
          font-size: 17px;
          text-decoration: none;
          color: lighten($brand-primary, 50%);
        }
        ul {
          width: 250px;
          margin: 10px 0;
          padding-left: 10px;
          li {
            padding: 5px 9px;
            a {
              font-size: 16px;
              text-decoration: none;
              color: lighten($brand-primary, 40%);
            }
          }
        }
      }
    }
    .footer {
      text-align: center;
      font-size: 13px;
      color: var(--body-gray);
    }
  }
}

@media (max-width: $MQDesktop) {
  // Overwrite one of the bootstrap navbar breakpoints (768px->992px)
  .navbar-toggle {
    display: block;
  }
  .container-fluid > .navbar-header {
    margin-right: -15px;
  }
  .navbar-header {
    float: none;
  }
  // Only shows on desktop, hide on mobile
  #huxblog_navbar {
    display: none;
  }
  // Old Mobile Navbar
  // /**
  //  * Layout
  //  * Since V1.6 we use absolute positioning to prevent to expand container-fluid
  //  * which would cover tags. A absolute positioning make a new layer.
  //  */
  // .navbar-default .navbar-collapse {
  //   position: absolute;
  //   right: 0;
  //   border: none;
  //   background: white;
  //   box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
  //   box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
  //     rgba(0, 0, 0, 0.239216) 0px 1px 4px;
  //   border-radius: 2px;
  //   width: 170px;
  // }
  // /**
  //  * Animation
  //  * HuxBlog-Navbar using genuine Material Design Animation
  //  */
  // #huxblog_navbar {
  //   /**
  // 	 * Sharable code and 'out' function
  // 	 */
  //   // the container do width-transition
  //   opacity: 0;
  //   transform: scaleX(0);
  //   transform-origin: top right;
  //   transition: all 200ms cubic-bezier(0.47, 0, 0.4, 0.99) 0ms;
  //   -webkit-transform: scaleX(0);
  //   -webkit-transform-origin: top right;
  //   -webkit-transition: all 200ms cubic-bezier(0.47, 0, 0.4, 0.99) 0ms;
  //   a {
  //     font-size: 13px;
  //     line-height: 28px;
  //   }
  //   .navbar-collapse {
  //     // the navbar do height-transition
  //     height: 0px; // to solve container-mask-tags issue (default state).
  //     transform: scaleY(0);
  //     transform-origin: top right;
  //     transition: transform 400ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
  //     -webkit-transform: scaleY(0);
  //     -webkit-transform-origin: top right;
  //     -webkit-transition: -webkit-transform 400ms cubic-bezier(0.32, 1, 0.23, 1)
  //       0ms;
  //   }
  //   li {
  //     opacity: 0;
  //     transition: opacity 100ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  //     -webkit-transition: opacity 100ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  //   }
  //   /**
  // 	 *'In' Animation
  // 	 */
  //   &.in {
  //     transform: scaleX(1);
  //     -webkit-transform: scaleX(1);
  //     opacity: 1;
  //     transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  //     -webkit-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  //     .navbar-collapse {
  //       transform: scaleY(1);
  //       -webkit-transform: scaleY(1);
  //       transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
  //       -webkit-transition: -webkit-transform 500ms
  //         cubic-bezier(0.23, 1, 0.32, 1);
  //     }
  //     li {
  //       opacity: 1;
  //       transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 205ms;
  //       -webkit-transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 205ms;
  //     }
  //   }
  // }
}

.navbar-custom {
  // materialize
  background: transparent;
  border-bottom: 1px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  @include sans-serif();
  .navbar-brand {
    font-weight: 800;
    // materialize
    color: white;
    line-height: 20px;
    padding: 20px;
    @include gungnir-font();
    &:hover {
      color: $white-faded;
    }
  }
  .nav {
    li {
      i {
        width: 18px;
      }
      a {
        color: white;
        padding: 20px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        font-weight: 800;
        letter-spacing: 1px;
        text-decoration: none;
        cursor: pointer;
        &:hover,
        &:focus {
          color: $white-faded;
        }
      }
      &:hover .sub-menu {
        display: inline-block;
        @include transition(all 0.4s);
      }
      .sub-menu {
        display: none;
        background: var(--bg);
        list-style-type: none;
        position: absolute;
        padding: 10px 0;
        top: 55px;
        left: 50%;
        margin-left: -50px;
        width: 105px;
        border-radius: 5px;
        box-shadow: var(--dropdown-shadow);
        -webkit-animation: fadeIn 0.3s 0.1s ease both;
        -moz-animation: fadeIn 0.3s 0.1s ease both;
        li {
          margin-left: -9px;
          padding: 5px 0;
          a {
            color: var(--body-color);
            &:hover,
            &:active,
            &:focus {
              color: var(--primary-color);
            }
          }
        }
        &:before {
          content: "";
          position: absolute;
          top: -20px;
          left: 50%;
          margin-left: -10px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent var(--bg) transparent;
        }
      }
    }
  }
  &.invert {
    .navbar-toggle {
      &:active {
        background-color: rgba(0, 0, 0, 0.05);
      }
      .icon-bar {
        background-color: var(--body-color);
      }
    }
    .navbar-brand {
      color: var(--body-color);
      &:hover,
      &:focus {
        color: var(--primary-color);
      }
    }
    .nav {
      li {
        a {
          color: var(--body-color);
          &:hover,
          &:focus {
            color: var(--primary-color);
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1170px) {
    @include transition(background-color 0.3s);
    /* Force Hardware Acceleration in WebKit */
    @include transform(translate3d(0, 0, 0));
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &.is-fixed {
      /* when the user scrolls down, we hide the header right above the viewport */
      position: fixed;
      top: -61px;
      background-color: var(--bg-transparent);
      border-bottom: 1px solid var(--border-color);
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
      .navbar-brand {
        color: var(--body-color);
        &:hover,
        &:focus {
          color: var(--primary-color);
        }
      }
      .nav {
        li {
          a {
            color: var(--body-color);
            &:hover,
            &:focus {
              color: var(--primary-color);
            }
          }
        }
      }
    }
    &.is-visible {
      /* if the user changes the scrolling direction, we show the header */
      -webkit-transform: translate3d(0, 100%, 0);
      -moz-transform: translate3d(0, 100%, 0);
      -ms-transform: translate3d(0, 100%, 0);
      -o-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
}

/* Hux Optimize UserExperience */
.navbar-default .navbar-toggle {
  &:focus,
  &:hover {
    background-color: inherit;
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

/* Hux customize Style for navBar button */
.navbar-default .navbar-toggle {
  border-color: transparent;
  padding: 19px 16px;
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  border-radius: 50%;
  .icon-bar {
    margin-top: -1px;
    width: 22px;
    border-radius: 0px;
    background-color: white;
    transition-duration: 0.3s;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  // Renovamen: icon change when navbar menu open (mobile)
  &.navOpen .icon-bar {
    margin-top: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &.navOpen .icon-bar + .icon-bar {
    margin-top: -2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
