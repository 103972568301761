// Reovamen: modify 404 page

.notfound {
  height: 100%;
  margin-top: -160px;
  width: 100%;
  .error-info {
    position: absolute;
    left: 50%;
    margin-left: -170px;
    bottom: 35%;
    text-align: center;
    h1, p {
      @include gungnir-font();
    }
    h1 {
      font-size: 50px;
      margin-bottom: 40px;
    }
    p {
      font-size: 25px;
      font-weight: 600;
      line-height: 14px;
      a {
        text-decoration: none;
        &:after {
          content:'';
          position: absolute;
          width: 4px;
          height: 20px;
          margin-top: -2px;
          margin-left: 8px;
          animation: blink 1s infinite steps(1, start);
        }
        @keyframes blink {
          0%, 100% {
            background-color: currentColor;
          }
          50% {
            background-color: transparent;
          }
        }
      }
    }
  }
}
