.about-page .item,
.portfolio .item {
  width: 75%;
  max-width: 100%;
  max-height: 20rem;
  margin: 0 auto;
  // background-color: #223344;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  min-height: 11rem;
  border-radius: 10px;
  overflow: hidden;
  @include transition(all 0.3s ease);
  box-shadow: var(--item-shadow);
  &:hover {
    @include transform(translate(0px, -2px));
    box-shadow: var(--item-up-shadow);
  }
  .item-thumbnail {
    width: 25%;
    max-width: 100%;
    /*   height: auto; */
    min-height: 15rem;
    vertical-align: middle;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .item-content {
    padding: 2.5rem 2.5rem 1rem 2.5rem;
    width: 70%;
    // margin-left: 15px;
    .item-title {
      margin: 0 0 5px;
      font-size: 20px;
      font-weight: 1200;
      a {
        text-decoration: none;
        color: var(--body-color);
        &:hover {
          color: var(--primary-color);
        }
      }
    }
    a {
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color-dark);
      }
    }
  }
}

@media (max-width: $MQSmall) {
  .about-page .item,
  .portfolio .item {
    width: 96%;
    max-width: 90%;
    max-height: 40rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    .item-thumbnail {
      width: 100%;
      max-width: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    .item-content {
      width: 100%;
      .item-title {
        margin: 0 0 5px;
        a {
          font-size: 21px;
        }
      }
    }
  }
}

.projects h4,
.publications h4 {
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 50px;
  text-align: center;
  margin-top: -10px;
  a {
    color: var(--primary-color);
    &:hover {
      color: var(--primary-color-dark);
    }
  }
}

.publications .item-content {
  .item-title {
    margin-bottom: 25px;
  }
  p {
    margin-top: -10px;
    line-height: 10px;
  }
}
