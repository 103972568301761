.about-page .work-experience {
  width: 85%;
  margin: 0 auto;
  position: relative;
  @media (max-width: $MQMobile) {
    width: 80%;
    margin-bottom: 0;
  }
}

.about-page .timeline {
  position: relative;
  padding: 0;
  list-style: none;
  margin-top: 50px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25%;
    width: 2px;
    margin-left: -1.5px;
    background-color: var(--bg-secondary);
  }
  li {
    position: relative;
    margin-bottom: 50px;
    min-height: 150px;
    &:before,
    &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
    .timeline-panel {
      float: right;
      position: relative;
      padding: 0 20px 20px;
      width: 65%;
      text-align: left;
      .timeline-heading {
        margin-top: -20px;
        p.time {
          color: var(--body-gray);
        }
        p.institution {
          font-weight: bold;
          font-size: 20px;
          margin-top: -30px;
          margin-bottom: 10px;
        }
        p.job {
          font-weight: bold;
          font-size: 16px;
          margin-top: -15px;
          margin-bottom: 10px;
        }
      }
      .timeline-body {
        margin-top: -20px;
        font-size: 16px;
        ul {
          margin-bottom: -25px;
          li {
            margin-bottom: 0;
            margin-top: -25px;
            min-height: 0;
            &:before,
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .timeline-image {
      z-index: 2;
      position: absolute;
      left: 25%;
      width: 150px;
      height: 150px;
      margin-left: -75px;
      border: 7px solid var(--bg-secondary);
      border-radius: 100%;
      text-align: center;
      background-color: var(--bg-secondary);
      img {
        border-radius: 100%;
        max-width: 100%;
      }
    }
  }

  @media (max-width: $MQDesktop) {
    li {
      margin-bottom: 100px;
      min-height: 100px;
      .timeline-panel {
        width: 70%;
        padding: 0 30px 20px 20px;
      }
      .timeline-image {
        width: 100px;
        height: 100px;
        margin-left: -50px;
      }
    }
  }

  @media (max-width: $MQMobile) {
    &:before {
      left: 40px;
    }
    li {
      margin-bottom: 50px;
      min-height: 50px;
      .timeline-panel {
        width: 100%;
        padding: 0 20px 0 100px;
      }
      .timeline-image {
        left: 0;
        width: 80px;
        height: 80px;
        margin-left: 0;
      }
    }
  }
}
