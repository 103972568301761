@mixin alert-flat() {
  margin: 0 0 1rem;
  padding: 15px;
  border: initial;
  border-left: 5px solid;
  border-radius: 3px;
}

.post-container {
  p.success {
    @include alert-flat();
    background-color: rgba($success, 0.1);
    border-left-color: $success;
  }

  p.info {
    @include alert-flat();
    background-color: rgba($info, 0.1);
    border-left-color: $info;
  }

  p.warning {
    @include alert-flat();
    background-color: rgba($warning, 0.1);
    border-left-color: $warning;
  }

  p.error {
    @include alert-flat();
    background-color: rgba($error, 0.1);
    border-left-color: $error;
  }
}
