// In the list of posts
.postlist-container {
  margin-bottom: 15px;
}

// Only text
.post-preview {
  > a {
    color: var(--body-color);
    &:hover,
    &:focus {
      text-decoration: none;
      color: var(--primary-color);
    }
    > .post-title {
      font-size: 26px;
      line-height: 1.3;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    > .post-subtitle {
      font-size: 16px;
      line-height: 1.3;
      margin: 0;
      font-weight: 300;
      margin-bottom: 10px;
    }
  }
  > .post-meta {
    @include serif();
    color: $gray;
    font-size: 18px;
    margin-top: 0;
    > a {
      text-decoration: none;
      color: var(--body-color);
      &:hover,
      &:focus {
        color: var(--primary-color);
        text-decoration: underline;
      }
    }
  }
  @media (max-width: $MQMobile) {
    > a {
      > .post-title {
        font-size: 21px;
        margin-bottom: 8px;
      }
      > .post-subtitle {
        font-size: 15px;
      }
    }
    .post-meta {
      font-size: 16px;
    }
  }
}

// With image
// Renovamen: add styles for the images displaying on homepage
.post-preview-img {
  margin: 80px auto 100px;
  position: relative;
  padding: 0 40px;
  > a {
    display: inline-block;
    width: 600px;
    height: 340px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    img {
      display: block;
      height: 100%;
      @include transition(all 0.6s);
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &:nth-child(odd) {
    text-align: left;
    .else {
      left: 620px;
    }
  }
  &:nth-child(even) {
    text-align: right;
    .else {
      right: 620px;
    }
  }
  .else {
    position: absolute;
    text-align: left;
    top: 20px;
    height: 360px;
    width: 490px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    overflow-y: hidden;
    .post-meta {
      color: var(--body-gray);
      font-size: 12px;
      margin: 80px 0 0 80px;
    }
    a:hover,
    a:focus {
      text-decoration: none;
      color: var(--primary-color);
    }
    .post-title {
      font-size: 30px;
      font-weight: normal;
      line-height: 1.1;
      margin: 10px 100px 0 80px;
    }
    .post-subtitle {
      font-size: 16px;
      font-weight: normal;
    }
    .post-subtitle,
    .post-content-preview {
      margin: 10px 100px 0 80px;
      line-height: 1.8;
    }
  }
  @media (max-width: $MQLarge) {
    > a {
      width: 480px;
      height: 280px;
    }
    &:nth-child(odd) .else {
      left: 500px;
    }
    &:nth-child(even) .else {
      right: 500px;
    }
    .else {
      top: 10px;
      width: 420px;
      height: 290px;
      .post-meta {
        font-size: 12px;
        margin: 50px 0 0 60px;
      }
      .post-title {
        font-size: 24px;
        margin: 10px 60px 0 60px;
      }
      .post-subtitle,
      .post-content-preview {
        margin: 10px 60px 0 60px;
      }
    }
  }
  @media (max-width: $MQIpad) {
    padding: 0 15px;
    width: 100%;
    margin-top: 80px;
    background: var(--bg);
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-color);
    > a {
      display: block;
      margin: 0 auto;
      margin-top: -30px;
      width: 570px;
      height: 320px;
      border-radius: 10px;
    }
    .else {
      height: auto;
      position: static;
      width: 100%;
      margin: 0 auto;
      background: transparent;
      border: none;
      .post-meta {
        margin: 30px 0 0 20px;
      }
      .post-title {
        font-size: 24px;
        margin: 10px 20px 0 20px;
      }
      .post-subtitle,
      .post-content-preview {
        margin: 10px 20px 0 20px;
      }
    }
  }
  @media (max-width: $MQSmall) {
    margin-top: 100px;
    border-bottom: none;
    max-width: 400px;
    > a {
      width: 100%;
      height: 210px;
      margin-top: -60px;
    }
    .else {
      .post-meta,
      .post-subtitle,
      .post-title,
      .post-content-preview {
        margin-left: 5px;
      }
    }
  }
  // Pager
  & + .pager {
    padding: 0px 40px !important;
    @media (max-width: $MQIpad) {
      padding: 0 15px !important;
    }
  }
}

// Hux add home-page post-content-preview
.post-content-preview {
  font-size: 14px;
}
