.search-page {
  @include gungnir-font();
  background-color: var(--bg) !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  @include transition(0.25s ease);
  @include transform(translateY(100%));

  &.open {
    @include transform(translateY(0));
  }

  .search-close {
    float: right;
    margin-right: 30px;
    margin-top: 30px;
    font-size: 30px;
    color: #bdbbbb;
    @include transition(0.3s ease);
    &:hover {
      color: #696868;
    }
  }
  .search-title {
    margin: 100px auto;
    margin-bottom: 1em;
    font-weight: bold;
  }
  .input {
    text-align: center;
  }
  .search-field {
    margin-top: 10px;
    padding: 0;
    width: 50%;
    border: none;
    outline: none;
    font-size: 28px;
    font-weight: bold;
  }
  .search-results {
    margin-top: 20px;
    list-style-type: none;
    height: calc(100vh - 200px);
    overflow: auto;
    .result-item {
      width: 50%;
      margin: 0 auto;
      a {
        text-decoration: none;
        display: block;
      }
      > a {
        color: var(--body-color);
        &:hover,
        &:focus {
          text-decoration: none;
          color: var(--primary-color);
        }
      }
      &-title {
        font-size: 20px;
        line-height: 1.3;
        margin-top: 20px;
        margin-bottom: 8px;
        font-weight: bold;
      }
      &-subtitle {
        font-size: 16px;
        line-height: 1.3;
        margin: 0;
        margin-bottom: 12px;
      }
    }
  }
  .search-img-right {
    position: absolute;
    width: 180px;
    bottom: 30px;
    right: 30px;
    @media (max-width: $MQMobile) {
      display: none;
    }
  }
  @media (max-width: $MQIpad) {
    .search-field,
    .search-results .result-item {
      width: 80%;
    }
  }
}
